import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, CardContent, Typography, Box, CardActions } from "@mui/material";
import imagen1 from "../images/imagen1.jpg";
import imagen4 from "../images/imagen4.jpg";
import imagen5 from "../images/imagen5.jpg";
import imagen6 from "../images/imagen6.jpg";

const services = [
  {
    title: {
      es: "Accidentes",
      en: "Accidents",
    },
    description: {
      es: "Representación en casos de accidentes laborales, personales y de tránsito para obtener la compensación que merece.",
      en: "Representation in workplace, personal and traffic accident cases to obtain the compensation you deserve.",
    },
    image: imagen4,
  },
  {
    title: {
      es: "Defensa Criminal",
      en: "Criminal Defense",
    },
    description: {
      es: "Protección legal en cargos criminales con enfoque en consecuencias migratorias y defensa estratégica.",
      en: "Legal protection in criminal charges with focus on immigration consequences and strategic defense.",
    },
    image: imagen5,
  },
  {
    title: {
      es: "Inmigración",
      en: "Immigration",
    },
    description: {
      es: "Asesoría completa en trámites migratorios, residencias, ciudadanía y defensa ante procesos de deportación.",
      en: "Complete advice on immigration procedures, residency, citizenship and deportation defense.",
    },
    image: imagen1,
  },
  {
    title: {
      es: "Divorcio y Custodia",
      en: "Divorce and Custody",
    },
    description: {
      es: "Asistencia legal en separación de bienes, custodia de menores, pensión alimenticia y disputas familiares.",
      en: "Legal assistance in asset division, child custody, support and family disputes.",
    },
    image: imagen6,
  },
];

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 1536 }, items: 4 },
  desktop: { breakpoint: { max: 1536, min: 1024 }, items: 4 },
  tablet: { breakpoint: { max: 1024, min: 600 }, items: 2 },
  mobile: { breakpoint: { max: 600, min: 0 }, items: 2 },
};

export default function ServiciosCarousel({ onCardClick, language }) {
  const handleCardClick = (service) => {
    const localizedService = {
      title: service.title[language],
      description: service.description[language],
      image: service.image,
    };
    onCardClick(localizedService);
  };

  return (
    <Box
      sx={{
        py: 6,
        px: 2,
        backgroundColor: "#0d1c4e",
        color: "white",
      }}
    >
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        showDots={false}
        arrows={true}
        renderButtonGroupOutside={false}
        swipeable={true}
        draggable={true}
        shouldResetAutoplay={false}
        focusOnSelect={false}
      >
        {services.map((service, i) => (
          <Box key={i} px={1}>
            <Card
              onClick={() => handleCardClick(service)}
              sx={{
                backgroundColor: "#E4FDE1",
                maxWidth: 400,
                margin: "auto",
                borderRadius: 3,
                overflow: "hidden",
                boxShadow: 4,
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: 6,
                },
              }}
            >
              <Box
                sx={{
                  height: 300,
                  backgroundImage: `url(${service.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <CardContent
                sx={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  backgroundColor: "#E4FDE1",
                }}
              >
                <Typography
                  textAlign={"center"}
                  variant="h6"
                  fontWeight="bold"
                  gutterBottom
                >
                  {service.title[language]}
                </Typography>
                <Typography textAlign={"center"} variant="body2">
                  {service.description[language]}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}
