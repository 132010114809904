import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Link,
  Modal,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

import SwipeableViews from "react-swipeable-views";
import { MotionConfig, motion } from "framer-motion";
import GavelIcon from "@mui/icons-material/Gavel";
import PeopleIcon from "@mui/icons-material/People";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarsIcon from "@mui/icons-material/Stars";
import fondoHome1 from "../images/fondoHome.JPG";
import fondoHome from "../images/fondoHome.JPG";
import fondoHome3 from "../images/fondoHome3.jpg";
import fondoHome4 from "../images/fondoHome4.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SpeedIcon from "@mui/icons-material/Speed";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Carrusel1 from "../components/Carrusel1";
import { submitContactForm } from "../api/contacts";
import imagen1 from "../images/imagen1.jpg";
import imagen2 from "../images/imagen2.jpg";
import imagen3 from "../images/imagen3.jpg";
import imagen4 from "../images/imagen4.jpg";
import { autoPlay } from "react-swipeable-views-utils";
import Logo from "../images/Logo1.png";
function HomePage() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [language, setLanguage] = useState("en");
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [currentText, setCurrentText] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    success: true,
  });

  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  const texts =
    language === "es"
      ? [
          "Abogados con experiencia",
          "Defendemos tus derechos con excelencia",
          "Soluciones legales rápidas y efectivas",
        ]
      : [
          "Lawyers with experience",
          "We defend your rights with excellence",
          "Fast and effective legal solutions",
        ];

  const handleSubmitContact = async () => {
    setLoading(true);
    try {
      await submitContactForm(formData);
      setFormData({ name: "", email: "", message: "" });
      setOpen(false);
      setSnackbar({
        open: true,
        message: "Mensaje enviado correctamente",
        success: true,
      });
    } catch (error) {
      console.error("Error al enviar:", error);
      setSnackbar({
        open: true,
        message: "Ocurrió un error al enviar el mensaje",
        success: false,
      });
    } finally {
      setLoading(false);
    }
  };
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "es" ? "en" : "es"));
  };

  return (
    <Box>
      {/* Top Navigation Bar */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#00205B",
          backdropFilter: "blur(10px)",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            href="#"
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={Logo} // reemplaza con tu ruta real
              alt="Logo"
              style={{
                height: "80px",
                marginRight: "10px",
              }}
            />
          </Link>

          <Box sx={{ display: "flex" }}>
            <Button sx={{ mr: "2vh" }} onClick={toggleLanguage}>
              {language === "es" ? "US" : "ES"}
            </Button>

            <Button
              variant="contained"
              component="a"
              href="tel:+18772672766"
              sx={{
                backgroundColor: "#E4FDE1",
                color: "#227C9D",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                px: 2,
              }}
            >
              {/* Ícono visible solo en xs */}
              <Box
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  display: {
                    xs: "inline",
                    sm: "none",
                  },
                }}
              >
                <PhoneIcon />
              </Box>

              {/* Texto visible desde sm hacia arriba */}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "inline",
                  },
                }}
              >
                +1 (877) 267-2766
              </Box>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Scroll Snapping Container */}
      <Box
        sx={{
          scrollSnapType: "y mandatory",
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity={snackbar.success ? "success" : "error"}
            sx={{ width: "100%" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        {/* Hero Section */}
        <Box
          component="section"
          id="inicio"
          sx={{
            backgroundImage: `url(${fondoHome4})`,
            height: "100vh",
            px: 2,
            textAlign: "center",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            scrollSnapAlign: "start",
            "::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.6))",
              zIndex: 0,
            },
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h2"}
            fontWeight="bold"
            c
            textAlign="center"
            color="#d19a62"
            sx={{
              textShadow: "2px 2px 10px rgb(0, 0, 0)",
              px: 2,
              zIndex: 1,
            }}
          >
            {texts[currentText]}
          </Typography>
          <Typography
            variant="body1"
            color="#fff"
            sx={{
              mt: 2,
              maxWidth: 600,
              textShadow: "1px 1px 5px rgba(103, 99, 99, 0.53)",
              zIndex: 1,
            }}
          >
            {language === "es"
              ? "Asesoría jurídica integral para empresas y personas."
              : "Comprehensive legal advice for businesses and individuals."}
          </Typography>

          <Box
            sx={{
              mt: 5,
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#E4FDE1",
                fontWeight: "bold",
                marginBottom: "2vh",
                opacity: 0.7,
                "&:hover": {
                  opacity: 2,
                  backgroundColor: "#00205B", // asegura que no cambie el color
                },
              }}
              onClick={() => {
                setSelectedService({
                  title:
                    language === "es"
                      ? "Agenda tu Consulta"
                      : "Book Your Consultation",
                  description:
                    language === "es"
                      ? "Déjanos tus datos y nos pondremos en contacto contigo lo antes posible."
                      : "Leave us your details and we will contact you as soon as possible.",
                });
                setOpen(true);
              }}
            >
              {language === "es" ? "Contactanos" : "Contact Us"}
            </Button>

            <Button
              sx={{
                color: "#227C9D",
                backgroundColor: "#E4FDE1",
                fontWeight: "bold",
                marginBottom: "2vh",
                opacity: 0.5,
                "&:hover": {
                  opacity: 2,
                },
              }}
              variant="outlined"
              onClick={() => {
                const section = document.getElementById("servicios");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              {language === "es" ? "Conoce más" : "Learn More"}
            </Button>
          </Box>
        </Box>
        {/* Services Section */}
        <Box
          component="section"
          id="servicios"
          sx={{
            scrollSnapAlign: "start",
            minHeight: "100vh",
            backgroundColor: "#0d1c4e",

            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Carrusel1
            language={language}
            onCardClick={(service) => {
              setSelectedService(service);
              setOpen(true);
            }}
          />

          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", md: 500 },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                outline: "none",
              }}
            >
              {selectedService && (
                <>
                  <Typography variant="h6" gutterBottom>
                    {selectedService.title}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {selectedService.description}
                  </Typography>

                  <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                    {language === "es"
                      ? "¿Deseas más información? Contáctanos:"
                      : "Want more information? Contact us:"}
                  </Typography>

                  <TextField
                    fullWidth
                    label={language === "es" ? "Nombre" : "Name"}
                    sx={{ mb: 2 }}
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    label={language === "es" ? "Correo Electrónico" : "Email"}
                    sx={{ mb: 2 }}
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    label={language === "es" ? "Mensaje" : "Message"}
                    sx={{ mb: 2 }}
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmitContact}
                    disabled={loading}
                  >
                    {loading
                      ? language === "es"
                        ? "Enviando..."
                        : "Sending..."
                      : language === "es"
                      ? "Enviar Mensaje"
                      : "Send Message"}
                  </Button>
                </>
              )}
            </Box>
          </Modal>
        </Box>

        {/* About Section */}
        <Box
          component="section"
          id="nosotros"
          sx={{
            backgroundImage: `url(${fondoHome4})`,
            height: "100vh",
            px: 2,
            textAlign: "center",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            scrollSnapAlign: "start",
            "::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 0,
            },
          }}
        >
          <Typography
            color="white"
            variant={isMobile ? "h5" : "h4"}
            fontWeight="bold"
          >
            {language === "es"
              ? "Representación Legal Efectiva y Agresiva"
              : "Effective and Aggressive Legal Representation"}
          </Typography>

          <Typography
            color="white"
            variant="body1"
            sx={{
              mt: 2,
              maxWidth: 800,
              mx: "auto",
            }}
          >
            {language === "es"
              ? "Con un historial comprobado de representación legal exitosa, efectiva y agresiva, el abogado Taboada utiliza su educación y más de 13 años de experiencia legal para superar las expectativas del cliente."
              : "With a proven track record of successful, effective, and aggressive legal representation, Attorney Taboada uses his education and over 13 years of legal experience to exceed client expectations."}
          </Typography>

          <Grid
            color="white"
            container
            spacing={4}
            justifyContent="center"
            sx={{ mt: 4 }}
          >
            {[
              {
                text:
                  language === "es"
                    ? "100% Casos Ganados"
                    : "100% Success Rate",
                icon: (
                  <CheckCircleIcon sx={{ color: "#d19a62", fontSize: 40 }} />
                ),
              },
              {
                text:
                  language === "es"
                    ? "Servicio Legal Especializado"
                    : "Expert Legal Service",
                icon: (
                  <SupportAgentIcon sx={{ color: "#d19a62", fontSize: 40 }} />
                ),
              },
              {
                text:
                  language === "es"
                    ? "Resolución Rápida de Casos"
                    : "Quick Complete Case",
                icon: <SpeedIcon sx={{ color: "#d19a62", fontSize: 40 }} />,
              },
            ].map((item, idx) => (
              <Grid item xs={6} sm={3} key={idx}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: idx * 0.2 }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    {item.icon}
                    <Typography
                      color="white"
                      sx={{ mt: 1, fontWeight: "bold", color: "#fff" }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box
          component="section"
          id="testimonios"
          sx={{
            backgroundImage: `url(${fondoHome})`,
            backgroundSize: "cover",
            backgroundPosition: "left",
            scrollSnapAlign: "start",
            flexDirection: "column",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "#0d1c4e",
            color: "#fff",
            px: 2,
            height: "100vh",
          }}
        >
          <Grid container spacing={4} justifyContent="center" maxWidth="lg">
            {[
              {
                name: "María González",
                text:
                  language === "es"
                    ? "Gracias al equipo de C. Taboada, logré resolver un problema legal que me tenía angustiada hace años."
                    : "Thanks to the C. Taboada team, I was able to solve a legal issue that had been troubling me for years.",
              },
              {
                name: "Carlos Rivas",
                text:
                  language === "es"
                    ? "Profesionales muy dedicados. Me acompañaron durante todo el proceso con transparencia y compromiso."
                    : "Very dedicated professionals. They supported me throughout the entire process with transparency and commitment.",
              },
              {
                name: "Isabella Fernández",
                text:
                  language === "es"
                    ? "Excelente experiencia. Su asesoría fue clave para ganar mi caso."
                    : "Excellent experience. Their advice was key to winning my case.",
              },
            ].map((testimonial, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: idx * 0.2 }}
                >
                  <Box
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      borderRadius: 2,
                      p: 3,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      boxShadow: 3,
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      “{testimonial.text}”
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "bold", color: "#d19a62" }}
                    >
                      — {testimonial.name}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Footer with contact form and social links */}
        <Box
          component="section"
          sx={{
            scrollSnapAlign: "start",
            backgroundColor: "#d19a62",
            color: "white",
            py: 6,
            px: 3,
            textAlign: "center",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ padding: "2vh" }}>
                <Typography variant="h6" gutterBottom>
                  {language === "es" ? "Contáctanos" : "Contact Us"}
                </Typography>

                <TextField
                  fullWidth
                  label={language === "es" ? "Nombre" : "Name"}
                  sx={{ mb: 2, backgroundColor: "#E5FFDE" }}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  label={language === "es" ? "Correo Electrónico" : "Email"}
                  sx={{ mb: 2, backgroundColor: "#E5FFDE" }}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  label={language === "es" ? "Mensaje" : "Message"}
                  sx={{ mb: 2, backgroundColor: "#E5FFDE" }}
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmitContact}
                  disabled={loading}
                >
                  {loading
                    ? language === "es"
                      ? "Enviando..."
                      : "Sending..."
                    : language === "es"
                    ? "Enviar Mensaje"
                    : "Send Message"}
                </Button>
              </Box>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
              item
              xs={12}
              md={4}
            >
              <Typography variant="h6" gutterBottom>
                {language === "es" ? "Síguenos" : "Follow Us"}
              </Typography>

              <Box display="flex" justifyContent="center" gap={2} mb={2}>
                <IconButton
                  href="https://www.instagram.com/carlotaboadalawfirmpllc/"
                  target="_blank"
                  sx={{ color: "white" }}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/share/1R8dhzAMGS/"
                  target="_blank"
                  sx={{ color: "white" }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  href="mailto:Abogado@ctaboadalaw.com"
                  target="_blank"
                  sx={{ color: "white" }}
                >
                  <EmailIcon />
                </IconButton>
              </Box>

              <Typography variant="subtitle2" color="black" mb={1}>
                1092 E Los Ebanos Blvd, Brownsville, TX 78520
              </Typography>

              <Box
                component="iframe"
                src="https://www.google.com/maps?q=1092+E+Los+Ebanos+Blvd,+Brownsville,+TX+78520&output=embed"
                width="100%"
                height="200"
                style={{
                  border: 0,
                  borderRadius: "8px",
                  maxWidth: "300px",
                }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Grid>
          </Grid>

          <Typography variant="body2" sx={{ mt: 6 }}>
            &copy; {new Date().getFullYear()}{" "}
            {language === "es"
              ? "Firma Legal. Todos los derechos reservados."
              : "Law Firm. All rights reserved."}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HomePage;
